.upper-nav {
  display: flex;
  gap: 5%;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: 2%;
}
.day {
  padding-left: 2%;
}
.main-grid {
  display: flex;
  border: 1px solid RGB(236, 237, 239);
}
.date {
  font-size: 18px;
  letter-spacing: -1.8px;
  border-radius: 100%;
  color: rgb(60, 64, 67);
  cursor: pointer;
  display: inline-block;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
}
.month {
  font-weight: 500;
  letter-spacing: 0.8px;
}
.timesheet-info-hours {
  margin-bottom: 20%;
  display: flex;
  flex-direction: row;
}

.timesheet-info {
  padding-left: 5%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
}

.hours {
  white-space: nowrap; /* Prevent line breaks */
  margin-right: 10px; /* Added spacing between hours and desc */
  color: #3c4043;
}
.desc {
  flex-grow: 1;
  display: flex;
  text-align: left;
  color: #3c4043;
  fill: rgb(60, 64, 67);
  padding-left: 1%;
  margin-left: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.desc:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

.timesheet {
  padding-left: 1vw;
}
.ant-table-tbody > tr:hover > td {
  background-color: inherit;
}
.description-details {
  width: fit-content;
  margin: 0 auto;
}
.description-details * {
  width: fit-content;
  text-align: left;
}
.description-details > ul,
ol {
  margin-top: 5px;
  padding-left: 1rem;
}
.description-details > p {
  margin-bottom: 0;
}
.ant-table-tbody.even-row > tr:hover {
  background-color: #f0f0f0;
}
