.ant-layout-has-sider{
    background-color:white;
    min-height: 88vh;
}

.ant-layout-sider-children{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.side-bar{
    height: calc(100vh - 64px);
}
.sidebar-menu-item{
    font-size: 16px;
    display: flex !important;
    /* align-items: center; */
    gap: 0.5rem;
}
.main-layout{
    height: calc(100vh - 64px);
}
.sub-group>div{
    display: flex !important;
    gap: 0.5rem;
    text-align: left;
}