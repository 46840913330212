.radiogroup>*{
    width:250px;
    height:40px;
}

.radiogroup>*>span{
    transition: all ease-in;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.userbutton{
    position:absolute;
    width:86.5%;
    bottom: 0;
    margin-bottom: 40px;
}
.userbutton>*{
    width: 100%;
}
.timesheetModal>div>.ant-modal-content{
    padding-bottom: 0!important;
    padding-right: 5px;
}