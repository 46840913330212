.App {
  text-align: center;
}

main{
  height: calc(100vh - 64px);
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentWrapper {
  overflow: auto;
  text-align: start;
}

.contentWrapper::-webkit-scrollbar {
  width: 10px;
  margin-left:1rem;
}

.contentWrapper::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 5px;
}

.contentWrapper::-webkit-scrollbar-thumb {
  background: #001529;
  border-radius: 5px;
}

.header-sider{
  padding-right: 1%;
}

.selectDropDown{
  font-size: 16px;
  margin-bottom: 0.5rem;
  color: #307BFF;
  text-align:start;
}

.formScroll::-webkit-scrollbar {
  width: 0px;
  margin-left:1rem;
}

.formScroll::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 5px;
}

.formScroll::-webkit-scrollbar-thumb {
  background: #001529;
  border-radius: 5px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.chat-message {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  background-color: #f1f1f1;
  align-self: flex-start;
}

.my-message {
  align-self: flex-end;
  background-color: #d1e7dd;
}

.other-message {
  align-self: flex-start;
  background-color: #f8d7da;
}

.chat-message-content {
  display: flex;
  flex-direction: column;
}

.rbc-overlay{
  z-index: 200;
}
.custom-table-row {
  height: 20px !important;
  padding:10px 16px !important; 
}

.popupDiv {
  padding-right: 17px;
}

.popupDiv::-webkit-scrollbar {
  width: 7px;
}
.popupDiv::-webkit-scrollbar-thumb {
  background: #001529;
  border-radius: 7px;
}

.popupDiv::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
}

.dropdown-item {
  cursor: default; /* or cursor: none; */
}


