.main-calendar{
    height: 90vh;
}
.toolbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding-bottom: 1vh;
}
.left-toolbar{
    display: flex;
    gap: 10px;
}
.toolbar-container-right{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56.4%;
}
.toolbar-container-right .label{
  font-size: 1rem;
  font-weight: 600;
}
.even-cell .rbc-event {
    background-color: blue; 
    border-color: blue;
    color: white;
  }

.rbc-month-row{
  cursor: pointer;
  z-index: 0;
}
.blue-cell::after {
    content: '';
    position: absolute;
    top: 10%;
    right: 85%;
    width: 10px;
    height: 10px;
    background-color: blue;
    border-radius: 50%;
} 
.red-cell {
    position: relative;
    background-color: rgb(244, 139, 139);
    pointer-events: none;
}

/* .red-cell::after {
    content: '';
    position: absolute;
    top: 10%;
    right: 85%;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    pointer-events: none;
} */

/* .half-red-cell {
    position: relative;
    background: linear-gradient(to bottom right, rgb(247, 168, 168) 50%, white 50%);
    clip-path: polygon(0 0, 100% 0, 0 100%);
    pointer-events: none;
} */

/* .half-red-cell::after {
    content: '';
    position: absolute;
    top: 10%;
    right: 85%;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    pointer-events: none;

} */
.green-cell{
  position: relative;
  background-color: #65f767;
  pointer-events: none;
}
/* .green-cell::after {
  content: '';
  position: absolute;
  top: 10%;
  right: 85%;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  pointer-events: none;
} */
.main-form-heading{
  display: flex;
  justify-content: center;
}
.date-class {
    margin-top: 0;
    /* width: 100%; */
    /* display: flex;
    justify-content: center; */
}
.modal-heading{
    margin: 0;
    /* width: 100%;
    display: flex;
    justify-content: center; */
    /* font-size: 16px; */
}


.date-cell-main {
  display: flex;
  justify-content: left; 
  align-items: top; 
  height: 100%; 
  position: relative;
  padding: 5px;
  flex: 1 0;
  border-left: 1px solid #ddd;
}

/* .leave-event{
 color: #000;
 font-weight: 400;
  
} */

:where(.css-dev-only-do-not-override-1kuana8).ant-progress .ant-progress-text{
    display: none;
}
.ant-progress-inner{
  margin-bottom: 20px;
}
.rbc-date-cell{
  margin-bottom: 0.7%;
}
.ql-toolbar.ql-snow{
  border-radius: 5px 5px 0 0;
}
.ql-container.ql-snow{
  border-radius: 0 0 5px 5px;
}
.weekend{
  background: rgb(233, 235, 235);
}
/* .weekend.half-red-cell {
  background: linear-gradient(to bottom right, rgb(247, 168, 168) 50%, blue 50%);
  clip-path: polygon(0 0, 100% 0, 0 100%);
  pointer-events: none;
} */

.custom-timepicker-popup {
  padding-top: 25px !important; /* Add a margin to the top of the dropdown */
}

.custom-timepicker-popup .ant-picker-time-panel-column:first-child::before {
  content: "HH";
  position: absolute;
  top: 8px; /* Adjust as per your design */
  width: 10%;
  background-color: white; /* Or match the popup background */
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #474646;
  z-index: 10;
  padding: 4px 0;
  pointer-events: none; /* Ensures the label doesn't interfere with user interaction */
}

.custom-timepicker-popup .ant-picker-time-panel-column:nth-child(2)::before {
  content: "MM";
  position: absolute;
  top: 8px; /* Adjust as per your design */
  width: 10%;
  background-color: white; /* Or match the popup background */
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #474646;
  z-index: 10;
  padding: 4px 0;
  pointer-events: none; /* Ensures the label doesn't interfere with user interaction */
}



